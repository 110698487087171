@import "../../assets/scss/theme";

.modal {
  position: fixed;
  z-index: 999999 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .6);

  .modal__content {
    width: 50%;
    border: .1rem solid;
    border-radius: .3rem;
    transition: 1.1s ease-out;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include themed($themes) {
      background: t('background');
      border-color: t('border-color');
      color: t('text');
      box-shadow: -2rem 2rem 2rem t('shadow-color');
    }

    &.medium {
      width: 70%;
    }

    &.large {
      width: 90%;
    }

    h2 {
      border-bottom: .1rem solid;
      font-size: 1.6rem;
      padding: .5rem;
      margin: 0;

      @include themed($themes) {
        color: t('text-gray');
        border-color: t('border-color');
      }
    }

    .modal__body {
      padding: .5rem;
      max-height: 70vh;
      overflow: hidden auto;

      @include themed($themes) {
        background: t('background');
      }
    }

    .modal__actions {
      text-align: right;
      border-top: 1px solid;
      padding: .5rem;

      @include themed($themes) {
        background: t('background');
        border-color: t('border-color');
      }
    }
  }
}