@import "../../assets/scss/theme";

.btn {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: .1rem solid transparent;
  padding: .8rem 2.5rem;
  border-radius: .3rem;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  height: 3.9rem;

  &.btn-block {
    display: block;
    width: 100%;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  &.disabled, &:disabled {
    cursor: not-allowed;
  }

  &.btn-loading {
    position: relative;
    cursor: not-allowed;
  }
}

a.btn:not(.btn-link) {
  padding: 6.5px 25px;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  @include themed($themes) {
    background-color: t('btn-background');
    border-color: t('btn-border');
    color: t('btn-text');
  }

  &.disabled, &:disabled {
    cursor: not-allowed;

    @include themed($themes) {
      background-color: t('btn-disabled-background');
      border-color: t('btn-disabled-border');
      color: t('btn-disabled-text');
    }
  }

  &.btn-success {
    @include themed($themes) {
      background-color: t('btn-background-success');
      border-color: t('btn-border');
      color: t('btn-text');
    }

    &.disabled, &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        background-color: t('btn-background-disabled-success');
        border-color: t('btn-disabled-border');
        color: t('btn-disabled-text');
      }
    }
  }

  &.btn-warning {
    @include themed($themes) {
      background-color: t('btn-background-warning');
      border-color: t('btn-border');
      color: t('btn-text');
    }

    &.disabled, &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        background-color: t('btn-background-disabled-warning');
        border-color: t('btn-disabled-border');
        color: t('btn-disabled-text');
      }
    }
  }

  &.btn-danger {
    @include themed($themes) {
      background-color: t('btn-background-danger');
      border-color: t('btn-border');
      color: t('btn-text')
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        background-color: t('btn-background-disabled-danger');
        border-color: t('btn-disabled-border');
        color: t('btn-disabled-text');
      }
    }
  }

  &.btn-loading:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 4rem;
    margin-top: -0.2rem;
    margin-right: 3.2rem;
    width: .4rem;
    height: .4rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 1rem 0 #fff, 2rem 0 #fff, 3rem 0 #fff;

    @include themed($themes) {
      animation: t('light-button-animation') infinite 1s linear;
      box-shadow: 1rem 0 t('btn-text'), 2rem 0 t('btn-text'), 3rem 0 t('btn-text');
    }
  }
}

.btn-secondary {
  background-color: transparent;

  @include themed($themes) {
    border-color: t('btn-background');
    color: t('btn-background');
  }

  &.active {
    @include themed($themes) {
      background-color: t('btn-background');
      border-color: t('btn-border');
      color: t('btn-text');
    }
  }

  &:visited {
    @include themed($themes) {
      color: t('btn-background');
    }
  }

  &:hover {
    @include themed($themes) {
      box-shadow: inset 0 0 0 .2rem t('btn-background');
    }
  }

  &.disabled, &:disabled {
    cursor: not-allowed;

    @include themed($themes) {
      border-color: t('btn-disabled-border');
      color: t('btn-disabled-background')
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.btn-loading:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 4rem;
    margin-top: -0.2rem;
    margin-right: 3.2rem;
    width: .4rem;
    height: .4rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 1rem 0 #fff, 2rem 0 #fff, 3rem 0 #fff;

    @include themed($themes) {
      animation: t('dark-button-animation') infinite 1s linear;
      box-shadow: 1rem 0 t('btn-text'), 2rem 0 t('btn-text'), 3rem 0 t('btn-text');
    }
  }

  &.btn-success {
    @include themed($themes) {
      border-color: t('btn-background-success');
      color: t('btn-background-success');
    }

    &:hover {
      @include themed($themes) {
        box-shadow: inset 0 0 0 .2rem t('btn-background-success');
      }
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        border-color: t('btn-disabled-border-success');
        color: t('btn-disabled-background-success')
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.btn-loading:after {
      @include themed($themes) {
        animation: t('success-button-animation') infinite 1s linear;
        box-shadow: 1rem 0 t('btn-text'), 2rem 0 t('btn-text'), 3rem 0 t('btn-text');
      }
    }
  }

  &.btn-warning {
    @include themed($themes) {
      border-color: t('btn-background-warning');
      color: t('btn-background-warning');
    }

    &:hover {
      @include themed($themes) {
        box-shadow: inset 0 0 0 .2rem t('btn-background-warning');
      }
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        border-color: t('btn-disabled-border-warning');
        color: t('btn-disabled-background-warning')
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.btn-loading:after {
      @include themed($themes) {
        animation: t('warning-button-animation') infinite 1s linear;
        box-shadow: 1rem 0 t('btn-text'), 2rem 0 t('btn-text'), 3rem 0 t('btn-text');
      }
    }
  }

  &.btn-danger {
    @include themed($themes) {
      border-color: t('btn-background-danger');
      color: t('btn-background-danger');
    }

    &:hover {
      @include themed($themes) {
        box-shadow: inset 0 0 0 .2rem t('btn-background-danger');
      }
    }

    &.disabled,
    &:disabled {
      cursor: not-allowed;

      @include themed($themes) {
        border-color: t('btn-disabled-border-danger');
        color: t('btn-disabled-background-danger')
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.btn-loading:after {
      @include themed($themes) {
        animation: t('danger-button-animation') infinite 1s linear;
        box-shadow: 1rem 0 t('btn-text'), 2rem 0 t('btn-text'), 3rem 0 t('btn-text');
      }
    }
  }
}

.btn-link {
  display: inline;
  font-size: unset;
  font-weight: unset;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: unset;
  text-transform: unset;
  cursor: pointer;
  height: unset;
  text-decoration: none;
  transition: all .3s ease-in;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:visited {
    @include themed($themes) {
      color: t('text-gray');
    }
  }

  &:hover,
  &.active {
    background-color: transparent;

    @include themed($themes) {
      color: t('text-orange');
    }
  }

  &:active {
    background-color: transparent;

    @include themed($themes) {
      color: t('text-gray');
    }
  }

  &.disabled, &:disabled {
    background: transparent;
    cursor: not-allowed;

    @include themed($themes) {
      color: t('btn-disabled-background');
    }
  }

  &.btn-success {
    background: transparent;

    @include themed($themes) {
      color: t('btn-background-success');
    }

    /*&:hover {
      color: rgba($green, .7);
    }*/
  }

  &.btn-warning {
    background: transparent;

    @include themed($themes) {
      color: t('btn-background-warning');
    }

    /*&:hover {
      color: rgba($red, .7) !important;
    }*/
  }

  &.btn-danger {
    background: transparent;

    @include themed($themes) {
      color: t('btn-background-danger');
    }

    /*&:hover {
      color: rgba($red, .7) !important;
    }*/
  }
}

.btn-small {
  padding: .5rem;
  font-size: 1.4rem;
  height: 2.8rem;
}

.btn-group {
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  gap: .2rem;
  margin: .5rem 0;

  .btn {
    flex: 1 1 auto;
    border-radius: 0;
    height: auto;

    &:first-child {
      border-top-left-radius: .3rem;
      border-bottom-left-radius: .3rem;
    }

    &:last-child {
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }

    &.btn-small {
      padding: .3rem .8rem;
    }

    &.btn-block {
      width: auto;
    }
  }

  &.full-flex {
    display: flex;
    flex-flow: row wrap;
    grid-gap: .5rem;

    .btn {
      flex: 1;

      &.btn-small {
        padding: .3rem .8rem;
      }
    }
  }
}
