@import "../../assets/scss/theme";

.form-group {
  margin-bottom: 1rem;

  .description {
    font-size: 1.3rem;

    @include themed($themes) {
      color: t('text-gray');
    }
  }
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

.form-control {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  display: block;
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  background-clip: padding-box;
  border: .1rem solid;
  border-radius: 0;

  @include themed($themes) {
    background-color: t('background');
    border-color: t('border-color');
    color: t('text');
  }

  &:focus {
    outline: 0;

    @include themed($themes) {
      border-color: t('border-color-orange');
      box-shadow: inset 0 0 0 0.1rem t('border-color-orange');
    }
  }

  &::placeholder {
    opacity: 1;

    @include themed($themes) {
      color: t('text-gray');
    }
  }

  &:disabled,
  &[readonly] {
    opacity: 1;

    @include themed($themes) {
      background-color: t('input-disabled-background');
    }
  }

  &.is-invalid {
    @include themed($themes) {
      border-color: t('border-color-red');
    }
  }
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;

  @include themed($themes) {
    color: t('text-red');
  }
}

.form-control.is-invalid ~ .invalid-feedback,
.select.is-invalid ~ .invalid-feedback,
.radio.is-invalid ~ .invalid-feedback,
.checkbox.is-invalid ~ .invalid-feedback {
  display: block;
}

label {
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 0;
}

code {
  @include themed($themes) {
    color: t('text-red');
  }
}

textarea.form-control {
  height: unset;
}

.checkbox {
  position: relative;

  input[type=checkbox] {
    opacity: 0;
    position: absolute;

    + label:after {
      content: none;
    }

    &:hover:not(:checked) + label:after {
      content: '';
      @include themed($themes) {
        border-color: t('input-hover-symbol');
      }
    }

    &:checked + label:after {
      content: "";
    }

    &:checked:disabled + label {
      &:after {
        @include themed($themes) {
          border-color: t('input-disabled-symbol');
        }
      }

      &:before {
        @include themed($themes) {
          background-color: t('input-disabled-background');
        }
      }
    }
  }

  label {
    padding-left: 4.2rem;
    font-weight: normal;

    &:before {
      content: " ";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 2rem;
      border: .1rem solid;
      background-color: transparent;

      @include themed($themes) {
        border-color: t('border-color');
      }
    }

    &:after {
      content: "";
      display: inline-block;
      height: .9rem;
      width: 2rem;
      border-left: .3rem solid;
      border-bottom: .3rem solid;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: .3rem;

      @include themed($themes) {
        border-color: t('border-color-orange');
      }
    }
  }
}

.DayPickerInput {
  width: 100%;
}

.DayPicker {
  font-size: 1.6rem !important;

  .DayPicker-wrapper {
    border: .1rem solid;

    .DayPicker-Day {
      padding: .5rem;
      border: .1rem solid;
      outline: none;

      @include themed($themes) {
        border-color: t('background');
      }
    }

    .DayPicker-Day--weekend {
      @include themed($themes) {
        color: t('input-hover-symbol');
      }
    }

    .DayPicker-Day--today {
      @include themed($themes) {
        color: t('btn-disabled-border');
      }
    }

    @include themed($themes) {
      border-color: t('border-color');
    }
  }

  /*&.is-valid {
    .DayPicker-wrapper {
      border-color: $green;
    }
  }

  &.is-invalid {
    .DayPicker-wrapper {
      border-color: $red;
    }

    & ~ .invalid-feedback {
      display: block;
    }
  }*/
}

.DayPicker-Month {
  border-collapse: unset !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  border: .1rem solid;
  border-radius: 0;

  @include themed($themes) {
    background-color: t('background') !important;
    border-color: t('border-color');
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 0;

  @include themed($themes) {
    background-color: t('background-gray') !important;
    border-color: t('border-color');
    color: t('text-white') !important;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  border-radius: 0;

  @include themed($themes) {
    background-color: t('btn-background-disabled-warning') !important;
    border-color: t('border-color-orange');
    color: t('text') !important;
  }
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  border: .1rem solid;
  border-radius: 50%;

  @include themed($themes) {
    background-color: t('background-white') !important;
    border-color: t('border-color');
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 50%;

  @include themed($themes) {
    background-color: t('background-orange') !important;
    border-color: t('border-color-orange');
    color: t('text-white') !important;
  }
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day--start,
.DayPicker-Day--end,
.DayPicker-Day--selected {
  border-radius: 50% !important;
}


.select {
  .select__control {
    font-size: 1.8rem;
    font-weight: 300;
    background-clip: padding-box;
    border: .1rem solid;
    border-radius: 0;

    @include themed($themes) {
      background-color: t('background') !important;
      border-color: t('border-color');
      color: t('text') !important;
    }

    .select__single-value {
      @include themed($themes) {
        color: t('text') !important;
      }
    }

    &:focus,
    &.select__control--is-focused {
      outline: 0;
      box-shadow: none;
    }

    &:hover,
    &.select__control--menu-is-open {
      @include themed($themes) {
        border-color: t('border-color');
      }
    }

    &::placeholder {
      opacity: 1;

      @include themed($themes) {
        color: t('text-gray');
      }
    }
  }

  .select__multi-value {
    @include themed($themes) {
      background-color: t('background-light');
    }

    .select__multi-value__label {
      @include themed($themes) {
        color: t('text');
      }
    }

    .select__multi-value__remove {
      svg {
        margin-right: 0;
      }

      &:hover {
        @include themed($themes) {
          background-color: t('background-orange');
        }

        svg {
          @include themed($themes) {
            color: t('text-white');
          }
        }
      }
    }
  }
}


/*
!*.css-52qy5m {
  z-index: 999999 !important;
}*!

.radio {
  position: relative;

  input[type=radio] {
    opacity: 0;

    + label:after {
      content: none;
    }

    &:hover:not(:checked) + label:after {
      content: '';
      background-color: rgba($orange, .5);
    }

    &:checked + label:after {
      content: '';
    }
  }

  label {
    padding-left: 22px;
    font-weight: normal;

    &:before {
      content: " ";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: $border-thin + px solid $gray;
      border-radius: 50%;
      background-color: transparent;
    }

    &:after {
      content: "";
      display: inline-block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $orange;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
}


.dropzone {
  position: relative;
  height: 200px;
  width: 100%;
  background-color: $white;
  border: 2px dashed $gray;
  border-radius: $border-radius + px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;

  ~ .invalid-feedback {
    display: block;
  }

  &.highlight {
    background-color: $background;
    border-color: $orange;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  svg {
    color: rgba($gray, .3);
    font-size: 4em;
    margin-bottom: 15px;
  }

  #uploadIcon {
    display: none;
  }

  #cameraIcon {
    display: block;
  }

  @media (min-width: 414px) {
    #uploadIcon {
      display: none;
    }

    #cameraIcon {
      display: block;
    }
  }

  @media(min-width: 768px) {
    #uploadIcon {
      display: block;
    }

    #cameraIcon {
      display: none;
    }
  }
}

.uploaded-files {
  display: flex;
  flex-direction: column;

  .file {
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    div svg {
      font-size: 14px;
      margin-right: 5px;
    }
  }

  > .delete {
    text-align: right;
  }
}

.time {
  margin-top: 10px;
}

#react-time-range {
  padding: 0;

  select {
    appearance: none;
    padding: 6px;
    border: none;
    border-bottom: $border-thin + px solid $gray;
    background-color: $background;
    width: calc(100% - 40px);
    cursor: pointer;
    font-size: 18px;
    border-radius: 0;
    outline: none;
  }

  .component {
    padding: 0;
    display: inline-block;
    overflow: auto;

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-bottom: 1px solid $gray;
      width: 40px;
      height: 38px;
      background-color: $background;
      color: $gray;
      float: left;
    }

    select {
      height: 38px;
    }

    &#start-component {
      width: calc(50% - 5px);
    }

    &#end-component {
      width: calc(50% + 5px);

      .label {
        width: 50px;
      }

      select {
        width: calc(100% - 50px);
      }
    }
  }

  &.is-valid {
    .label,
    select {
      border-color: $green;
    }
  }

  &.is-invalid {
    .label,
    select {
      border-color: $red;
    }

    .error {
      width: 100%;
      padding: 0;
      font-size: 80%;
      color: $red;
    }
  }

  .error {
    color: $red;
    padding: 5px;
  }
}


.wrapper-progressBar {
  width: 100%;
  margin: 0 0 20px;

  .progressBar {
    counter-reset: step;

    li {
      list-style-type: none;
      float: left;
      position: relative;
      text-align: center;
      font-weight: 600;

      &:before {
        content: counter(step);
        counter-increment: step;
        height: 30px;
        width: 30px;
        line-height: 30px;
        border: 2px solid $gray;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: $white;
      }

      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 32px);
        height: 2px;
        background-color: $gray;
        top: 15px;
        left: calc(-50% + 16px);
      }

      &:first-child:after {
        content: none;
      }

      &.active {
        color: $orange;

        &:before {
          border-color: $orange;
        }

        &:after {
          background-color: $orange;
        }
      }
    }
  }

  :after {
    content: '';
    display: block;
    clear: both;
  }

}*/
