.account {
  .account-sidebar {
    margin-top: 3rem;

    .image {
      position: relative;
      margin-bottom: 20px;

      .btn {
        position: absolute;
        top: -1rem;
        left: -1rem;
        z-index: 1;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: .3rem;
        box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .16);
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    .btn-group {
      margin: 0 0 1rem 0;
    }

    @media(min-width: 768px) {
      margin-top: 0;
    }
  }

  .upload {
    input {
      display: none;
    }

    #uploadIcon {
      margin-right: 7.5px;
      display: none;
    }

    #cameraIcon {
      margin-right: 7.5px;
      display: inline-block;
    }

    @media (min-width: 414px) {
      #uploadIcon {
        display: none;
      }

      #cameraIcon {
        display: inline-block;
      }
    }

    @media(min-width: 768px) {
      #uploadIcon {
        display: inline-block;
      }

      #cameraIcon {
        display: none;
      }
    }
  }
}