$light-text-color: white;
$dark-text-color: black;
$my-class-bg-light: white;
$my-class-bg-dark: black;





$grid-columns: 12;
$gutter: 15;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;
$main-top-margins: (
        sm: 98px + 25px,
        md: 118px + 25px,
        lg: 133px + 25px,
        xl: 148px + 25px
) !default;