@import "../../assets/scss/theme";

.author {
  font-size: 1.3rem;

  @include themed($themes) {
    color: t('text-gray');
  }

  button {
    margin-left: 2.5rem;
  }
}


.detail-sidebar {
  position: relative;
  margin: 3rem 0;

  .overview-item {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    top: auto;
  }

  @media (min-width: 992px) {
    position: sticky;
    margin-top: 0;
    top: 15.7rem;
  }

  @media (min-width: 1200px) {
    top: 17.2rem;
  }
}

.attachment-wrapper {
  margin-top: 2rem;
}

.item-body:after {
  content: '';
  display: block;
  clear: both;
}

.tags {
  margin-top: 5rem;

  ul {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      border-radius: .3rem 0 0 .3rem;
      font-size: 1.3rem;
      display: inline-block;
      height: 2.2rem;
      line-height: 2.2rem;
      padding: 0 1.5rem 0 2rem;
      position: relative;
      margin: 0 1rem 1rem 0;
      text-decoration: none;

      @include themed($themes) {
        background-color: t('background-light');
        color: t('text-gray');
      }

      &::before {
        border-radius: 50%;
        content: '';
        height: .6rem;
        left: .8rem;
        position: absolute;
        width: .6rem;
        top: .8rem;

        @include themed($themes) {
          background-color: t('background');
          box-shadow: inset 0 .1rem t('shadow-color');
        }
      }

      &::after {
        border-bottom: 1.1rem solid transparent;
        border-left: .8rem solid;
        border-top: 1.1rem solid transparent;
        content: '';
        position: absolute;
        right: 0;
        top: 0;

        @include themed($themes) {
          background-color: t('background');
          border-left-color: t('background-light');
        }
      }
    }
  }
}
