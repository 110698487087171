@import '../../../assets/scss/theme';

.crownBar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 998;

  @include themed($themes) {
    background-color: t('background-gray');
  }

  .container {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .socials {
      align-self: flex-start;
    }

    .socials {
      padding-top: 2px;
      margin-right: 50px;
      position: relative;
      display: block;

      a {
        font-size: 16px;
      }
    }

    button, a {
      padding: 5px 10px;
      margin: 0;
      background: none;
      border: none;
      font-weight: bold;
      cursor: pointer;
      font-size: 14px;

      @include themed($themes) {
        color: t('text');
      }

      &:hover,
      &.active {
        @include themed($themes) {
          color: t('text-white');
        }
      }

      &:focus {
        outline: none;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}