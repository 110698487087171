@import "variables";


@keyframes waving-light__light-theme {
        0%{box-shadow: 1rem 0 #ffffff, 2rem 0 #ffffff, 3rem 0 #ffffff}
        10%{box-shadow: 1rem -.3rem #ffffff, 2rem 0 #ffffff,3rem 0 #ffffff}
        20%{box-shadow: 1rem -.6rem #ffffff, 2rem -.3rem #ffffff, 3rem 0 #ffffff}
        30%{box-shadow: 1rem -.3rem #ffffff, 2rem -.6rem #ffffff, 3rem -.3rem #ffffff}
        40%{box-shadow: 1rem 0 #ffffff, 2rem -.3rem #ffffff, 3rem -.6rem #ffffff}
        50%{box-shadow: 1rem 0 #ffffff, 2rem 0 #ffffff, 3rem -.3rem #ffffff}
        60%{box-shadow: 1rem 0 #ffffff, 2rem 0 #ffffff, 3rem 0 #ffffff}
        100%{box-shadow: 1rem 0 #ffffff, 2rem 0 #ffffff, 3rem 0 #ffffff}
}

@keyframes waving-dark__light-theme {
        0%{box-shadow: 1rem 0 #9d9694, 2rem 0 #9d9694, 3rem 0 #9d9694}
        10%{box-shadow: 1rem -.3rem #9d9694, 2rem 0 #9d9694,3rem 0 #9d9694}
        20%{box-shadow: 1rem -.6rem #9d9694, 2rem -.3rem #9d9694, 3rem 0 #9d9694}
        30%{box-shadow: 1rem -.3rem #9d9694, 2rem -.6rem #9d9694, 3rem -.3rem #9d9694}
        40%{box-shadow: 1rem 0 #9d9694, 2rem -.3rem #9d9694, 3rem -.6rem #9d9694}
        50%{box-shadow: 1rem 0 #9d9694, 2rem 0 #9d9694, 3rem -.3rem #9d9694}
        60%{box-shadow: 1rem 0 #9d9694, 2rem 0 #9d9694, 3rem 0 #9d9694}
        100%{box-shadow: 1rem 0 #9d9694, 2rem 0 #9d9694, 3rem 0 #9d9694}
}

@keyframes waving-success__light-theme {
        0%{box-shadow: 1rem 0 #2eab18, 2rem 0 #2eab18, 3rem 0 #2eab18}
        10%{box-shadow: 1rem -.3rem #2eab18, 2rem 0 #2eab18,3rem 0 #2eab18}
        20%{box-shadow: 1rem -.6rem #2eab18, 2rem -.3rem #2eab18, 3rem 0 #2eab18}
        30%{box-shadow: 1rem -.3rem #2eab18, 2rem -.6rem #2eab18, 3rem -.3rem #2eab18}
        40%{box-shadow: 1rem 0 #2eab18, 2rem -.3rem #2eab18, 3rem -.6rem #2eab18}
        50%{box-shadow: 1rem 0 #2eab18, 2rem 0 #2eab18, 3rem -.3rem #2eab18}
        60%{box-shadow: 1rem 0 #2eab18, 2rem 0 #2eab18, 3rem 0 #2eab18}
        100%{box-shadow: 1rem 0 #2eab18, 2rem 0 #2eab18, 3rem 0 #2eab18}
}

@keyframes waving-warning__light-theme {
        0%{box-shadow: 1rem 0 #ef7d00, 2rem 0 #ef7d00, 3rem 0 #ef7d00}
        10%{box-shadow: 1rem -.3rem #ef7d00, 2rem 0 #ef7d00,3rem 0 #ef7d00}
        20%{box-shadow: 1rem -.6rem #ef7d00, 2rem -.3rem #ef7d00, 3rem 0 #ef7d00}
        30%{box-shadow: 1rem -.3rem #ef7d00, 2rem -.6rem #ef7d00, 3rem -.3rem #ef7d00}
        40%{box-shadow: 1rem 0 #ef7d00, 2rem -.3rem #ef7d00, 3rem -.6rem #ef7d00}
        50%{box-shadow: 1rem 0 #ef7d00, 2rem 0 #ef7d00, 3rem -.3rem #ef7d00}
        60%{box-shadow: 1rem 0 #ef7d00, 2rem 0 #ef7d00, 3rem 0 #ef7d00}
        100%{box-shadow: 1rem 0 #ef7d00, 2rem 0 #ef7d00, 3rem 0 #ef7d00}
}

@keyframes waving-danger__light-theme {
        0%{box-shadow: 1rem 0 #ab182d, 2rem 0 #ab182d, 3rem 0 #ab182d}
        10%{box-shadow: 1rem -.3rem #ab182d, 2rem 0 #ab182d,3rem 0 #ab182d}
        20%{box-shadow: 1rem -.6rem #ab182d, 2rem -.3rem #ab182d, 3rem 0 #ab182d}
        30%{box-shadow: 1rem -.3rem #ab182d, 2rem -.6rem #ab182d, 3rem -.3rem #ab182d}
        40%{box-shadow: 1rem 0 #ab182d, 2rem -.3rem #ab182d, 3rem -.6rem #ab182d}
        50%{box-shadow: 1rem 0 #ab182d, 2rem 0 #ab182d, 3rem -.3rem #ab182d}
        60%{box-shadow: 1rem 0 #ab182d, 2rem 0 #ab182d, 3rem 0 #ab182d}
        100%{box-shadow: 1rem 0 #ab182d, 2rem 0 #ab182d, 3rem 0 #ab182d}
}

@keyframes waving-light__dark-theme {
        0%{box-shadow: 1rem 0 #cfcfcf, 2rem 0 #cfcfcf, 3rem 0 #cfcfcf}
        10%{box-shadow: 1rem -.3rem #cfcfcf, 2rem 0 #cfcfcf,3rem 0 #cfcfcf}
        20%{box-shadow: 1rem -.6rem #cfcfcf, 2rem -.3rem #cfcfcf, 3rem 0 #cfcfcf}
        30%{box-shadow: 1rem -.3rem #cfcfcf, 2rem -.6rem #cfcfcf, 3rem -.3rem #cfcfcf}
        40%{box-shadow: 1rem 0 #cfcfcf, 2rem -.3rem #cfcfcf, 3rem -.6rem #cfcfcf}
        50%{box-shadow: 1rem 0 #cfcfcf, 2rem 0 #cfcfcf, 3rem -.3rem #cfcfcf}
        60%{box-shadow: 1rem 0 #cfcfcf, 2rem 0 #cfcfcf, 3rem 0 #cfcfcf}
        100%{box-shadow: 1rem 0 #cfcfcf, 2rem 0 #cfcfcf, 3rem 0 #cfcfcf}
}

@keyframes waving-dark__dark-theme {
        0%{box-shadow: 1rem 0 #474241, 2rem 0 #474241, 3rem 0 #474241}
        10%{box-shadow: 1rem -.3rem #474241, 2rem 0 #474241,3rem 0 #474241}
        20%{box-shadow: 1rem -.6rem #474241, 2rem -.3rem #474241, 3rem 0 #474241}
        30%{box-shadow: 1rem -.3rem #474241, 2rem -.6rem #474241, 3rem -.3rem #474241}
        40%{box-shadow: 1rem 0 #474241, 2rem -.3rem #474241, 3rem -.6rem #474241}
        50%{box-shadow: 1rem 0 #474241, 2rem 0 #474241, 3rem -.3rem #474241}
        60%{box-shadow: 1rem 0 #474241, 2rem 0 #474241, 3rem 0 #474241}
        100%{box-shadow: 1rem 0 #474241, 2rem 0 #474241, 3rem 0 #474241}
}

@keyframes waving-success__dark-theme {
        0%{box-shadow: 1rem 0 #144c0a, 2rem 0 #144c0a, 3rem 0 #144c0a}
        10%{box-shadow: 1rem -.3rem #144c0a, 2rem 0 #144c0a,3rem 0 #144c0a}
        20%{box-shadow: 1rem -.6rem #144c0a, 2rem -.3rem #144c0a, 3rem 0 #144c0a}
        30%{box-shadow: 1rem -.3rem #144c0a, 2rem -.6rem #144c0a, 3rem -.3rem #144c0a}
        40%{box-shadow: 1rem 0 #144c0a, 2rem -.3rem #144c0a, 3rem -.6rem #144c0a}
        50%{box-shadow: 1rem 0 #144c0a, 2rem 0 #144c0a, 3rem -.3rem #144c0a}
        60%{box-shadow: 1rem 0 #144c0a, 2rem 0 #144c0a, 3rem 0 #144c0a}
        100%{box-shadow: 1rem 0 #144c0a, 2rem 0 #144c0a, 3rem 0 #144c0a}
}

@keyframes waving-warning__dark-theme {
        0%{box-shadow: 1rem 0 #6b3800, 2rem 0 #6b3800, 3rem 0 #6b3800}
        10%{box-shadow: 1rem -.3rem #6b3800, 2rem 0 #6b3800,3rem 0 #6b3800}
        20%{box-shadow: 1rem -.6rem #6b3800, 2rem -.3rem #6b3800, 3rem 0 #6b3800}
        30%{box-shadow: 1rem -.3rem #6b3800, 2rem -.6rem #6b3800, 3rem -.3rem #6b3800}
        40%{box-shadow: 1rem 0 #6b3800, 2rem -.3rem #6b3800, 3rem -.6rem #6b3800}
        50%{box-shadow: 1rem 0 #6b3800, 2rem 0 #6b3800, 3rem -.3rem #6b3800}
        60%{box-shadow: 1rem 0 #6b3800, 2rem 0 #6b3800, 3rem 0 #6b3800}
        100%{box-shadow: 1rem 0 #6b3800, 2rem 0 #6b3800, 3rem 0 #6b3800}
}

@keyframes waving-danger__dark-theme {
        0%{box-shadow: 1rem 0 #4c0a14, 2rem 0 #4c0a14, 3rem 0 #4c0a14}
        10%{box-shadow: 1rem -.3rem #4c0a14, 2rem 0 #4c0a14,3rem 0 #4c0a14}
        20%{box-shadow: 1rem -.6rem #4c0a14, 2rem -.3rem #4c0a14, 3rem 0 #4c0a14}
        30%{box-shadow: 1rem -.3rem #4c0a14, 2rem -.6rem #4c0a14, 3rem -.3rem #4c0a14}
        40%{box-shadow: 1rem 0 #4c0a14, 2rem -.3rem #4c0a14, 3rem -.6rem #4c0a14}
        50%{box-shadow: 1rem 0 #4c0a14, 2rem 0 #4c0a14, 3rem -.3rem #4c0a14}
        60%{box-shadow: 1rem 0 #4c0a14, 2rem 0 #4c0a14, 3rem 0 #4c0a14}
        100%{box-shadow: 1rem 0 #4c0a14, 2rem 0 #4c0a14, 3rem 0 #4c0a14}
}

$themes: (
        light: (
                background: #FAFAFA,
                background-gray: #9d9694,
                background-white: #ffffff,
                background-orange: #ef7d00,
                background-light: rgba(157, 150, 148, .2),

                text: #333333,
                text-white: #ffffff,
                text-gray: #9d9694,
                text-orange: #ef7d00,
                text-red: #ab182d,
                text-green: #2eab18,
                text-blue: #27348b,
                text-light: rgba(157, 150, 148, .2),

                shadow-color: rgba(0, 0, 0, .16),

                border-color: #9d9694,
                border-color-orange: #ef7d00,
                border-color-red: #ab182d,

                input-disabled-background: #ebeae9,
                input-hover-symbol: rgba(157, 150, 148, .4),
                input-disabled-symbol: rgba(157, 150, 148, .4),

                btn-background: #9d9694,
                btn-background-success: #2eab18,
                btn-background-warning: #ef7d00,
                btn-background-danger: #ab182d,
                btn-border: #000000,
                btn-text: #ffffff,

                btn-disabled-background: #b0aba9,
                btn-background-disabled-success: #86eb74,
                btn-background-disabled-warning: #ffbe77,
                btn-background-disabled-danger: #eb7485,
                btn-disabled-border: #9d9694,
                btn-disabled-text: rgba(255,255,255,.4),

                toast-background-success: #f0fcee,
                toast-icon-success: #7fea6c,
                toast-background-warning: #fff7ee,
                toast-icon-warning: #ffba6f,
                toast-background-danger: #fceef0,
                toast-icon-danger: #ea6c7e,
                toast-background-info: #eff0fa,
                toast-icon-info: #7582d8,

                color-animation: colors-light,
                light-button-animation: waving-light__light-theme,
                dark-button-animation: waving-dark__light-theme,
                success-button-animation: waving-success__light-theme,
                warning-button-animation: waving-warning__light-theme,
                danger-button-animation: waving-danger__light-theme,

                image-opacity: 1
        ),
        dark: (
                background: #272524,
                background-gray: #474241,
                background-white: #322f2e,
                background-orange: #a45500,
                background-light: rgba(122, 114, 111, .2),

                text: #bababa,
                text-white: #bababa,
                text-gray: #7a726f,
                text-orange: #a45500,
                text-red: #c61b34,
                text-green: #35c61b,
                text-blue: #2e3da4,
                text-light: rgba(122, 114, 111, .2),

                shadow-color: rgba(0, 0, 0, .16),

                border-color: #7a726f,
                border-color-orange: #a45500,
                border-color-red: #660e1b,

                input-disabled-background: #181616,
                input-hover-symbol: rgba(24, 22, 22, .4),
                input-disabled-symbol: rgba(24, 22, 22, .4),

                btn-background: #5f5957,
                btn-background-success: #1b660e,
                btn-background-warning: #8f4b00,
                btn-background-danger: #660e1b,
                btn-border: #191919,
                btn-text: #cfcfcf,

                btn-disabled-background: #3f3b3a,
                btn-background-disabled-success: #144c0a,
                btn-background-disabled-warning: #6b3800,
                btn-background-disabled-danger: #4c0a14,
                btn-disabled-border: #5f5957,
                btn-disabled-text: rgba(0,0,0,.4),

                toast-background-success: #0c2907,
                toast-icon-success: #144c0a,
                toast-background-warning: #311a00,
                toast-icon-warning: #6b3800,
                toast-background-danger: #29070c,
                toast-icon-danger: #4c0a14,
                toast-background-info: #0b0d25,
                toast-icon-info: #1d2668,

                color-animation: colors-dark,
                light-button-animation: waving-light__dark-theme,
                dark-button-animation: waving-dark__dark-theme,
                success-button-animation: waving-success__dark-theme,
                warning-button-animation: waving-warning__dark-theme,
                danger-button-animation: waving-danger__dark-theme,

                image-opacity: .8,
        ),
);



@mixin themed($themes) {
        @each $theme, $map in $themes {
                .theme--#{$theme} & {
                        $theme-map: () !global;
                        @each $key, $submap in $map {
                                $value: map-get(map-get($themes, $theme), '#{$key}');
                                $theme-map: map-merge($theme-map, ($key: $value)) !global;
                        }
                        @content;
                        $theme-map: null !global;
                }
        }
}

@mixin base($themes) {
        @each $theme, $map in $themes {
                &.theme--#{$theme} {
                        $theme-map: () !global;
                        @each $key, $submap in $map {
                                $value: map-get(map-get($themes, $theme), '#{$key}');
                                $theme-map: map-merge($theme-map, ($key: $value)) !global;
                        }
                        @content;
                        $theme-map: null !global;
                }
        }
}

@function t($key) {
        @return map-get($theme-map, $key);
}