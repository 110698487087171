@import "../../assets/scss/theme";



/*
.loader {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 1.6rem;
}

.loader:before {
  animation: loader-wave infinite 1s linear;
  position: absolute;
  display: block;
  content: "";
  width: .4rem;
  height: .4rem;
  box-shadow: 1rem 0 #666, 2rem 0 #666, 3rem 0 #666;
  border-radius: 50%;
  margin-top: -0.2rem;
  margin-left: -2.2rem;
  top: 50%;
  left: 50%;
}

.button--loading {
  background-color: #cbd2da;
  border-color: #cbd2da;
  position: relative;
  cursor: not-allowed;
  color: #333332;
  padding: 0 8rem 0 4rem;
}

.button--loading::after {
  animation: waving-light infinite 1s linear;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 4rem;
  margin-top: -0.2rem;
  margin-right: 3.2rem;
  width: .4rem;
  height: .4rem;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 1rem 0 #fff, 2rem 0 #fff, 3rem 0 #fff;
}

@keyframes loader-wave {
  0%{box-shadow: 10px 0 #666, 20px 0 #666, 30px 0 #666}
  10%{box-shadow:10px -3px #666, 20px 0 #666, 30px 0 #666}
  20%{box-shadow:10px -6px #666, 20px -3px #666, 30px 0 #666}
  30%{box-shadow:10px -3px #666, 20px -6px #666, 30px -3px #666}
  40%{box-shadow:10px 0 #666, 20px -3px #666, 30px -6px #666}
  50%{box-shadow:10px 0 #666, 20px 0 #666, 30px -3px #666}
  60%{box-shadow:10px 0 #666, 20px 0 #666, 30px 0 #666}
  100%{box-shadow:10px 0 #666, 20px 0 #666, 30px 0 #666}
}

@keyframes waving-light {
  0%{box-shadow:10px 0px #333332,20px 0px #333332,30px 0px #333332}
  10%{box-shadow:10px -3px #333332,20px 0px #333332,30px 0px #333332}
  20%{box-shadow:10px -6px #333332,20px -3px #333332,30px 0px #333332}
  30%{box-shadow:10px -3px #333332,20px -6px #333332,30px -3px #333332}
  40%{box-shadow:10px 0px #333332,20px -3px #333332,30px -6px #333332}
  50%{box-shadow:10px 0px #333332,20px 0px #333332,30px -3px #333332}
  60%{box-shadow:10px 0px #333332,20px 0px #333332,30px 0px #333332}
  100%{box-shadow:10px 0px #333332,20px 0px #333332,30px 0px #333332}
}


*/

.loading {
  width: 5rem;
  height: 5rem;
  margin: 5rem auto;

  &.wide {
    width: 27.9rem;
    height: 5rem;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include themed($themes) {
        animation-name: t('color-animation');
      }
    }
  }

  /*.ellipsis {
    font-size: 40px;
    margin-top: 20px;
    font-weight: 500;

    @include themed($themes) {
      color: t('text-gray');
    }

    animation-timing-function: linear;
    animation-duration: 2s;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis steps(4,end) 1500ms infinite;
      content: "\2026";
      width: 0;
    }
  }

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
    margin: 60px auto;
  }

  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    margin: 70px auto;
  }

  @media (min-width: 992px) {
    width: 80px;
    height: 80px;
    margin: 80px auto;
  }

  @media (min-width: 1200px) {
    width: 100px;
    height: 100px;
    margin: 100px auto;

    &.wide {
      height: 100px;
    }
  }*/
}

/*@keyframes blink {
  0% {
    @include themed($themes) {
      fill: t('text-gray');
      color: t('text-gray');
    }
  }
  100% {
    @include themed($themes) {
      fill: t('btn-disabled-text');
      color: t('btn-disabled-text');
    }
  }
}
@-webkit-keyframes blink {
  0% {
    @include themed($themes) {
      fill: t('text-gray');
      color: t('text-gray');
    }
  }
  100% {
    @include themed($themes) {
      fill: t('btn-disabled-text');
      color: t('btn-disabled-text');
    }
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}*/

@keyframes colors-light {
  0% { fill: rgba(157, 150, 148, 1); }
  100% { fill: rgba(157, 150, 148, .4); }
}

@keyframes colors-dark {
  0% { fill: rgba(71, 66, 65, 1); }
  100% { fill: rgba(71, 66, 65, .4); }
}