@import "../../assets/scss/theme";

.overview-item {
  display: flex;
  flex-direction: column;
  border: .1rem solid;
  border-radius: .3rem;
  padding: 1rem;
  margin-bottom: 1rem;
  height: calc(100% - 1rem);

  @include themed($themes) {
    background-color: t('background-white');
    border-color: t('border-color');
    box-shadow: 0 .3rem .3rem t('shadow-color');
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5.4rem;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: .3rem solid;

    @include themed($themes) {
      border-color: t('border-color');
    }

    h2 {
      margin: 0;

      span {
        font-size: 2rem;
        margin-left: 2rem;

        @include themed($themes) {
          color: t('text-gray');
        }
      }
    }

    img {
      width: 4.8rem;
      height: 4.8rem;
      margin-right: 1rem;
      object-fit: cover;
    }

    @media screen and (max-width: 767px) {
      height: 5.1rem;

      img {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }

  .content {
    .date {
      font-weight: 600;
    }

    .time {
      font-size: 1.3rem;

      svg {
        margin-right: .7rem;
      }
    }

    .date-array {
      .date {
        display: inline-block;
        font-size: 1.3rem;
      }

      .time {
        display: inline-block;
        margin-left: 1rem;

        svg {
          display: none;
        }
      }
    }

    .instructor,
    .organisers {
      font-size: 1.3rem;

      svg {
        width: 1.6rem;
        margin-right: 1rem;

        @include themed($themes) {
          color: t('text-gray')
        }
      }
    }

    .up-cv {
      margin-top: 1.5rem;
    }

    .organisers {
      margin: 1.5rem 0;

      .organiser {
        padding: 0;
        margin: -.2rem 0 0 .4rem;
        list-style: none;
        display: block;
        float: left;
      }

      svg {
        margin-top: .2rem;
        float: left;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .location {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1.3rem;

      span {
        margin: auto 0;
      }

      svg {
        width: 1.6rem;
        margin-right: 1rem;
        font-size: 2em;

        @include themed($themes) {
          color: t('text-gray')
        }
      }
    }

    .btn-group {
      display: flex;
      margin: 2rem 0 0 0;
      justify-content: flex-end;

      .btn {
        flex: 0 1 auto;
      }
    }
  }
}