@import "../../assets/scss/theme";

.slick-slide {
  height: 25rem;

  @include themed($themes) {
    background-color: t('background-light');
  }

  img {
    height: 25rem;
    width: 100% !important;
    object-fit: cover;
  }

  & > div > div {
    height: unset !important;
    width: 100% !important;
  }

  .loading {
    height: 25rem;
    margin: auto;
  }

  @media (min-width: 576px) {
    height: 30rem;

    img {
      height: 30rem;
    }

    .loading {
      height: 30rem;
    }
  }

  @media (min-width: 768px) {
    height: 40rem;

    img {
      height: 40rem;
      object-fit: contain;
    }

    .loading {
      height: 40rem;
    }
  }

  @media (min-width: 992px) {
    height: 45rem;

    img {
      height: 45rem;
    }

    .loading {
      height: 45rem;
    }
  }

  @media (min-width: 1200px) {
    height: 50rem;

    img {
      height: 50rem;
    }

    .loading {
      height: 50rem;
    }
  }
}

.slick-dots {
  width: calc(100% - 1rem);
  text-align: right;
  bottom: .5rem;
  margin: 0 .5rem;

  li {
    width: 1rem;
    height: 1rem;

    &.slick-active button:before {
      opacity: 1;

      @include themed($themes) {
        color: t('text-gray');
      }
    }

    button {
      &:before {
        opacity: 1;

        @include themed($themes) {
          color: t('text-white');
        }
      }

      &:hover:before {
        @include themed($themes) {
          color: t('text-orange');
        }
      }
    }
  }
}

.slick-arrows {
  position: absolute;
  top: .5rem;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:hover {
    @include themed($themes) {
      color: t('text-orange');
    }
  }

  &.prev {
    left: .5rem;
  }

  &.next {
    left: 3rem;
  }
}
