.login {
    margin: auto;

    .login__logo {
        width: 50%;
        margin: 2rem 25% 5rem;

        @media (min-width: 57.6rem), (min-height: 32.1rem) {
            width: 75%;
            margin-right: 12.5%;
            margin-left: 12.5%;
        }
    }

    @media (max-width: 41.4rem) {
        .btn-group {
            display: block;

            .btn {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }
}