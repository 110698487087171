@import '../../assets/scss/variables';

@mixin media-breakpoint($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@function breakpoint($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, 0);
}


main {
  padding-top: 88px + 25px;
  padding-bottom: 50px;

  @each $breakpoint, $main-top-margin in $main-top-margins {
    @include media-breakpoint($breakpoint, $grid-breakpoints) {
      padding-top: $main-top-margin;
    }
  }
}


.container {
  width: 100%;
  padding-right: $gutter + px;
  padding-left: $gutter + px;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.container-fluid {
  width: 100%;
  padding-right: $gutter + px;
  padding-left: $gutter + px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter + px;
  margin-right: -$gutter + px;

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

%grid-column {
  position: relative;
  width: 100%;
  padding-left: $gutter + px;
  padding-right: $gutter + px;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: if(breakpoint($breakpoint, $grid-breakpoints) == null, "", "-#{$breakpoint}");

  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }

  .col#{$infix},
  .col#{$infix}-auto {
    @extend %grid-column;
  }

  @include media-breakpoint($breakpoint, $grid-breakpoints) {
    .col#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col#{$infix}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        flex: 0 0 percentage($i / $grid-columns);
        max-width: percentage($i / $grid-columns);
      }
    }

    .order#{$infix}-first { order: -1; }

    .order#{$infix}-last { order: $grid-columns + 1; }

    @for $i from 0 through $grid-columns {
      .order#{$infix}-#{$i} { order: $i; }
    }

    @for $i from 0 through ($grid-columns - 1) {
      @if not ($infix == "" and $i == 0) {
        .offset#{$infix}-#{$i} {
          $num: $i / $grid-columns;
          margin-left: if($num == 0, 0, percentage($num));
        }
      }
    }

    @if not ($infix == "") {
      .d#{$infix}-none {
        display: none;
      }
    }
  }
}