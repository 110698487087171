@import "theme";

*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  @include themed($themes) {
    opacity: t('image-opacity');
  }
}

html {
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%;
  margin: 0;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
}

.base {
  min-height: 100vh;

  @include base($themes) {
    background-color: t('background');
    color: t('text');
  }
}

p {
  margin-bottom: 1.8rem;
}

h1, .h1 {
  font-size: 4.8rem;
  font-weight: 300;
  margin: 0 0 6rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t('text');
  }

  div {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.2;

    @include themed($themes) {
      color: t('text-orange');
    }
  }
}

h2, .h2 {
  font-size: 3.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t('text');
  }

  &:not(:first-child) {
    margin-top: 4rem;
  }
}

h3, .h3 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 2rem;
  line-height: 1.2rem;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:not(:first-child) {
    margin-top: 4rem;
  }
}

h4, .h4 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.8rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:not(:first-child) {
    margin-top: 3.6rem;
  }
}

h5, .h5 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:not(:first-child) {
    margin-top: 3.2rem;
  }
}

h6, .h6 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.4rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:not(:first-child) {
    margin-top: 2.8rem;
  }
}


@media screen and (max-width: 767px) {
  body {
    font-size: 1.5rem;
    line-height: 1.33;
  }

  h1, .h1 {
    font-size: 3rem;
    margin-bottom: 3rem;

    div {
      font-size: 1.8rem;
    }
  }

  h2, .h2 {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  h4, .h4 {
    font-size: 1.8rem;
    margin-bottom: 1.4rem;
  }

  h5, .h5 {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
  }

  h6, .h6 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}




dl {
  dt {
    font-weight: 700;
    margin-bottom: .5rem;
  }
  dd {
    margin-left: 1.5rem;
  }
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

a:not(.btn) {
  text-decoration: none;
  transition: color .3s ease-in;

  @include themed($themes) {
    color: t('text-gray');
  }

  &:visited {
    @include themed($themes) {
      color: t('text-gray');
    }
  }
  &:hover,
  &.active {
    @include themed($themes) {
      color: t('text-orange');
    }
  }
  &:active {
    @include themed($themes) {
      color: t('text-gray');
    }
  }
}