@import "../../../assets/scss/theme";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;

  @include themed($themes) {
    background: t('background');
  }

  .container {
    border-bottom: .3rem solid;
    display: flex;

    @include themed($themes) {
      background-color: t('background');
      border-color: t('background-gray');
    }
  }

  .logo {
    width: auto;
    height: 30px;
    margin: 15px 0;

    @media (min-width: 414px) {
      height: 50px;
      margin: 5px 0;
    }

    @media (min-width: 576px) {
      height: 60px;
    }

    @media (min-width: 768px) {
      height: 70px;
      margin: 10px 0;
    }

    @media (min-width: 992px) {
      height: 75px;
      margin: 15px 0;
    }

    @media (min-width: 1200px) {
      height: 80px;
      margin: 20px 0;
    }
  }

  nav {
    display: flex;
    align-items: end;
    margin: 5px 0 5px auto;

    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 17px;
      font-weight: 500;
      padding: 0;

      @include themed($themes) {
        color: t('text-gray');
      }

      &:hover {
        @include themed($themes) {
          color: t('text-orange');
        }
      }

      &:focus {
        outline: none;
      }
    }

    @media (min-width: 768px) {
      margin: 10px 0 10px auto;
    }

    @media (min-width: 992px) {
      margin: 15px 0 15px auto;
    }

    @media (min-width: 1200px) {
      margin: 20px 0 20px auto;
    }

    .navbar {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      text-align: right;

      .navbar-item {
        margin: 0 15px;
        font-size: 17px;
        display: inline;

        a {
          font-weight: 500;

          span {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }

      @media (min-width: 330px) {
        .navbar-item {
          margin: 0 10px;
        }
      }

      @media (min-width: 768px) {
        .navbar-item {
          a {
            span {
              display: inline;
            }

            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

.crownBar + header {
  top: 24px;
}

/*


.hamburger {
  display: block;
  position: fixed;
  overflow: auto;
  padding: 20px;
  margin: 0;
  list-style: none;
  background: $white;
  right: -90%;
  top: 0;
  height: 100%;
  width: 90%;
  text-align: left;
  box-shadow: none;
  transition: all .3s ease-out;
  z-index: 1002;

  .navbar-item {
    border-bottom: $border-thin + px solid $gray;
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: $bold;

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;

      svg {
        margin-right: 20px;
      }

      .chevron {
        transition: all .3s ease-out;
        position: absolute;
        margin-right: 0;
        right: 0;
      }

      &:hover {
        .chevron {
          right: 15px;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .button {
    text-align: right;
    position: sticky;
    top: 0;
    border-bottom: none;

    button {
      font-size: 1.5em;
    }
  }

  &.hamburger-show {
    right: 0;
    box-shadow: 0 3px 6px rgba($black, .16);
  }

  @media (min-width: 576px) {
    right: -60%;
    width: 60%;
  }

  @media (min-width: 768px) {
    right: -50%;
    width: 50%;
  }

  @media (min-width: 992px) {
    right: -40%;
    width: 40%;
  }

  @media (min-width: 1200px) {
    right: -30%;
    width: 30%;
  }
}

.dropdown {
  position: fixed;
  right: 20px;
  left: 20px;
  top: 65px;

  .dropdown-menu {
    display: none;
    width: 100%;
    box-shadow: 0 3px 6px rgba($black, .16);
    border: $border-thin + px solid $gray;
    border-radius: $border-radius + px;
    padding: 0;
    background: $white;
    font-size: .6em;
    list-style: none;

    .head {
      padding: 5px 15px;
      border-radius: $border-radius + px $border-radius + px 0 0;
      background: $gray;
      display: flex;
      justify-content: space-between;
      color: $white;

      a {
        color: $white;

        &:hover {
          color: $orange;
        }
      }
    }

    .footer {
      padding: 5px 15px;
      border-radius: 0 0 $border-radius + px $border-radius + px;
      background: $gray;
      text-align: center;

      a,
      button {
        color: $white;

        &:hover {
          color: $orange;
        }
      }
    }

    .notification-wrapper {
      overflow: auto;
      max-height: calc(100vh - 174px);
      padding: 0;

      .notification-box{
        padding: 10px 15px;
        display: flex;
        text-align: left;
        border-bottom: $border-thin + px solid $gray;

        &:last-child {
          border: none;
        }

        &.unseen {
          background: rgba($orange, .06);
        }

        a {
          font-weight: $regular;
          width: 100%;
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          box-shadow: 0 3px 6px rgba(51, 51, 51, 0.16);
          margin-right: 15px;
        }

        > div {
          flex: 1;
        }

        strong {
          color: $orange;
        }

        small {
          color: $gray;
          float: right;
          margin-top: 10px;
        }
      }
    }

    .empty-list {
      margin: 20px auto;
      height: auto;
    }

    &.open {
      display: block;
    }

    &:before {
      content: "";
      position: absolute;
      top: -20px;
      right: 12px;
      border: 10px solid $gray;
      border-color: transparent transparent $gray transparent;
    }
  }

  &.alert .dropdown-menu:before {
    right: 120px;
  }

  &.notification .dropdown-menu:before {
    right: 78px;
  }

  @media (min-width: 576px) {
    position: absolute;
    top: 40px;
    left: unset;
    right: -10px;

    .dropdown-menu {
      width: 400px;
    }

    &.alert .dropdown-menu:before,
    &.notification .dropdown-menu:before {
      right: 11px;
    }
  }

  @media (min-width: 768px) {
    .dropdown-menu {
      width: 460px;
    }

    &.alert .dropdown-menu:before,
    &.notification .dropdown-menu:before {
      right: 12px;
    }
  }

  @media (min-width: 992px) {
    &.alert .dropdown-menu:before,
    &.notification .dropdown-menu:before {
      right: 10px;
    }
  }

  @media (min-width: 1200px) {
    &.alert .dropdown-menu:before,
    &.notification .dropdown-menu:before {
      right: 12px;
    }
  }
}
*/



