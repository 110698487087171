@import "../../assets/scss/theme";

.attachments {
  font-weight: 500;
  padding: 1rem;
  border-radius: .3rem;

  @include themed($themes) {
    background-color: t('background-gray');
    color: t('text-white');
    border-color: t('border-color');
    box-shadow: 0 .3rem .3rem t('shadow-color');
  }

  span {
    display: block;
    padding-bottom: 1rem;
  }

  .attachment {
    display: flex;
    justify-content: space-between;

    span {
      width: 100%;

      .btn-link {
        display: block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .btn-link {
      font-weight: normal;

      @include themed($themes) {
        color: t('text-white');
      }

      &:hover {
        color: orange;
      }
    }

    svg {
      margin-right: 1rem;

      @include themed($themes) {
        color: t('text-white');
      }
    }
  }
}