.pills-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .2rem;
  margin: 0 0 2rem 0;

  .btn {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: .3rem;
      border-bottom-left-radius: .3rem;
    }

    &:last-child {
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }
}