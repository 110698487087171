@import "../../assets/scss/theme";

.no-content {
  height: 100px;
  text-align: center;
  margin: 100px auto;
  font-size: 3.2rem;
  text-transform: uppercase;

  @include themed($themes) {
    color: t('text-light');
  }
}